const awsmobile = {
    "aws_project_region": "us-east-1",
    "aws_cognito_region": "us-east-1",
    "aws_user_pools_id": "us-east-1_f9ZUChRZJ",
    "aws_user_pools_web_client_id": "43o6pqkgmhg26pka9onqpnod0t",   // cognito app client id
    "oauth": {
      "domain": "cec-userpooldomain-sbx.auth.us-east-1.amazoncognito.com",
      "scope": [
        "email",
        "profile",
        "openid"
        ],
      "redirectSignIn": "https://www.cec-admin-sbx.duke-energy.app/",
      "redirectSignOut": "https://www.cec-admin-sbx.duke-energy.app/",
      "responseType": "token"
    },
    "aws_appsync_graphqlEndpoint": "https://gpx4nueks5eqta3iwvbbz63zee.appsync-api.us-east-1.amazonaws.com/graphql",
    "aws_appsync_region": "us-east-1",
    "aws_appsync_authenticationType": "AMAZON_COGNITO_USER_POOLS"
};

export default awsmobile;
