
export const environments = {
    local: {
        //api: 'https://iqlqamn90g.execute-api.us-east-1.amazonaws.com/dev/postgraphile',
        api: 'http://localhost:9002/graphql',
        auth: 'https://emp-dev-api.duke-energy.com/o365/oauth2/v2/token',
        azure_client_id: '1a9a757e-17b1-429a-97b6-234058633425',
        azure_tenant: '2ede383a-7e1f-4357-a846-85886b2c0c4d',
        consumer_key: 'j7Fg0NxDRxfbKBT6lGG6kD8KvzUJuO8L',
        consumer_secret: '6AZaXIT7aBVJaTuZ',
        unauthorized_route: '/unauthorized',
        azure_redirect_uri: 'http://localhost:4200/admin/admin-dashboard',
        portal_uri: 'localhost:4200',
        debugging_enabled: false,
    },
    dev: {
        portal_uri: 'https://cec-customerdashboard-dev.duke-energy.app'
    },
    sbx: {
        portal_uri: 'https://cec-customerdashboard-sbx.duke-energy.app',
      azure_redirect_uri: 'https://www.cec-admin-sbx.duke-energy.app/',
    },
    qa: {
        portal_uri: 'https://cec-customerdashboard-qa.duke-energy.app'
    },
    prod: {
        portal_uri: 'https://cec-customerdashboard.duke-energy.app'
    },
};
