// Angular imports...
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { AppComponent } from './app.component';
import { ApiService, DataService, AllocationTableService,
   CustomerTableService} from 'src/app/services/index';

// Third-party...
import {MatCheckboxModule, MatExpansionModule, MatTableModule, MatPaginatorModule, MatFormFieldModule, MatInputModule,
   MatSelectModule, MatCardModule, MatDatepickerModule, MatRadioModule, MatNativeDateModule, MatDialogModule,
    MatIconModule, MatSlideToggleModule,
    MatSnackBarModule, MatTooltipModule} from '@angular/material';
import { DeFooterModule, DeHeaderModule, DeButtonModule, DeLoaderModule } from '@de-electron/electron-angular-components';
import { RouterModule } from '@angular/router';
import { AppRoutingModule } from './app-routing.module';
import { AdminComponent } from './components/admin/admin.component';
import { AllocationTableComponent } from './components/allocation-table/allocation-table.component';
import { CustomerLookupComponent } from './components/customer-lookup/customer-lookup.component';
import { CustomerDetailComponent } from './components/customer-detail/customer-detail.component';
import { AccountPanelComponent } from './components/account-panel/account-panel.component';
import { SubscriptionComponent } from './components/subscription/subscription.component';
import { IneligibilityReasonsDialogComponent } from './components/ineligibility-reasons-dialog/ineligibility-reasons-dialog.component';
import { FacilityManagementReportComponent } from './components/facility-management/facility-management-report.component';
import { FacilityManagementService } from './services/facility-management.service';
import { SegmentService } from './services/segment.service';
import { AllocationReportComponent } from './components/allocation-report/allocation-report.component';
import { DetailsDialogComponent } from './components/details-dialog/details-dialog.component';
import { SolarFacilityComponent } from './components/solar-facility/solar-facility.component';
import { MonthlyCapacityComponent } from './components/monthly-capacity-factor/monthly-capacity-factor.component';
import { EnrollmentFeeDialogComponent } from './components/enrollment-fee-dialog/enrollment-fee-dialog.component';
import { TotalCapacityBoxComponent } from './components/total-capacity-box/total-capacity-box.component';
import { ReportDropDownComponent } from './components/report-drop-downs/report-drop-downs.component';
import { DateRowComponent } from './components/date-row/date-row.component';
import { ProgramReportComponent } from './components/program-report/program-report.component';
import { ProgramTableComponent } from './components/program-table/program-table.component';
import { AdminDashboardComponent } from './components/admin-dashboard/admin-dashboard.component';
import { FinancialsBoxComponent } from './components/financials-box/financials-box.component';
import { CustomerTotalBoxComponent } from './components/customer-total-box/customer-total-box.component';
import { CapacityBoxComponent } from './components/capacity-box/capacity-box.component';
import { AlertsBoxComponent } from './components/alerts-box/alerts-box.component';
import { ProgramDetailComponent } from './components/program-detail/program-detail.component';
import { WaitListComponent } from './components/wait-list/wait-list.component';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { NgxChartsModule } from '@swimlane/ngx-charts';
import { ComboChartComponent, ComboSeriesVerticalComponent } from './components/combo-chart';
import { ViewFacilityComponent } from './components/view-facility/view-facility.component';
import { DetailOutputExpectedComponent } from './components/detail-output-expected/detail-output-expected.component';
import { StyleHelperService } from './services/style-service.service';
import { FinancialReportComponent } from './components/financial-report/financial-report.component';
import { FinancialReportTableComponent } from './components/financial-report-table/financial-report-table.component';
import { ErrorDialogComponent } from './components/error-dialog/error-dialog.component';
import { UserAdminSettingComponent } from './components/user-admin-setting/user-admin-setting.component';
import { FacilityAlertsComponent } from './components/facility-alerts/facility-alerts.component';
import { environments } from 'src/config/env.config';
import { env } from 'src/config/env';
import { FacilityAlertsReportComponent } from './components/facility-alerts-report/facility-alerts-report.component';
import { FacilityAlertsReportTableComponent } from './components/facility-alerts-report-table/facility-alerts-report-table.component';
import { EnrollmentAlertsReportComponent } from './components/enrollment-alerts-report/enrollment-alerts-report.component';
import { EnrollmentAlertsReportTableComponent } from './components/enrollment-alerts-report-table/enrollment-alerts-report-table.component';
import { CustomerAlertsReportComponent } from './components/customer-alerts-report/customer-alerts-report.component';
import { CustomerAlertsReportTableComponent } from './components/customer-alerts-report-table/customer-alerts-report-table.component';
import { AlertDialogComponent } from './components/alert-dialog/alert-dialog.component';
import { AlertMainPageComponent } from './components/alert-main-page/alert-main-page.component';

// Auth/Config libraries
import { IPublicClientApplication, PublicClientApplication, InteractionType, BrowserCacheLocation, LogLevel } from '@azure/msal-browser';
import { MsalGuard, MsalInterceptor, MsalBroadcastService, MsalInterceptorConfiguration, MsalModule, MsalService, MSAL_GUARD_CONFIG, MSAL_INSTANCE, MSAL_INTERCEPTOR_CONFIG, MsalGuardConfiguration, MsalRedirectComponent } from '@azure/msal-angular';


import { TableVirtualScrollModule } from 'ng-table-virtual-scroll';
import { EnrollmentAlertsMainPageComponent } from './components/enrollment-alerts-main-page/enrollment-alerts-main-page.component';
import { CustomerAlertsMainPageComponent } from './components/customer-alerts-main-page/customer-alerts-main-page.component';
import { FacilityAlertsMainPageComponent } from './components/facility-alerts-report-main-page/facility-alerts-main-page.component';


/* import AmplifyUIAngularModule  */
import { AmplifyUIAngularModule } from '@aws-amplify/ui-angular';
import { KwSliderComponent } from './components/kw-slider/kw-slider.component';
import { RSPControlsComponent } from './components/rsp-controls/rsp-controls.component';
import { RSPEnrollmentDialogComponent } from './components/rsp-enrollment-dialog/rsp-enrollment-dialog.component';
import { EnrollmentAuditReportComponent } from './components/enrollment-audit-report/enrollment-audit-report.component';
import { EnrollmentAuditReportTableComponent } from './components/enrollment-audit-report-table/enrollment-audit-report-table.component';
import { LoginTurnOffComponent } from './components/login-turn-off/login-turn-off.component';
import { NgxTextDiffModule } from 'ngx-text-diff';
import { EnrolledAllocationsReportComponent } from './components/enrolled-allocations-report/enrolled-allocations-report.component';
import { EnrolledAllocationsReportTableComponent } from './components/enrolled-allocations-report-table/enrolled-allocations-report-table.component';
import { TransferListComponent } from './components/transfer-list/transfer-list.component';

const isIE = window.navigator.userAgent.indexOf("MSIE ") > -1 || window.navigator.userAgent.indexOf("Trident/") > -1;

export function MSALInstanceFactory(): IPublicClientApplication {
  return new PublicClientApplication({
    auth: {
      clientId: environments[env.env].azure_client_id,
      authority: 'https://login.microsoftonline.com/' +  environments[env.env].azure_tenant,
      redirectUri: environments[env.env]['azure_redirect_uri']
    },
    cache: {
      cacheLocation: BrowserCacheLocation.LocalStorage,
      storeAuthStateInCookie: isIE , // set to true for IE 11 IPublicClientApplication
    },
    system: {
      loggerOptions: {
        loggerCallback,
        logLevel: LogLevel.Info,
        piiLoggingEnabled: false
      }
    }
  });
}

export function MSALGuardConfigFactory(): MsalGuardConfiguration {
  return { 
    interactionType: InteractionType.Redirect,
    authRequest: {
      scopes: ['user.read']
    }
  };
}

export function MSALInterceptorConfigFactory(): MsalInterceptorConfiguration {
  const protectedResourceMap = new Map<string, Array<string>>();
  //protectedResourceMap.set('https://graph.microsoft.com/v1.0/me', ['user.read']);
  //protectedResourceMap.set('https://8o1i93dkda.execute-api.us-east-1.amazonaws.com/beta/', ['api://8f3fc48a-2226-46f9-b755-a638d91e76d0/user_impersonation']);

  return {
    interactionType: InteractionType.Redirect,
    protectedResourceMap
  };
}

@NgModule({
  declarations: [
    AppComponent,
    AdminComponent,
    AllocationTableComponent,
    CustomerLookupComponent,
    CustomerDetailComponent,
    AccountPanelComponent,
    SubscriptionComponent,
    FacilityManagementReportComponent,
    IneligibilityReasonsDialogComponent,
    EnrollmentFeeDialogComponent,
    DetailsDialogComponent,
    RSPEnrollmentDialogComponent,
    ErrorDialogComponent,
    AllocationReportComponent,
    SolarFacilityComponent,
    MonthlyCapacityComponent,
    TotalCapacityBoxComponent,
    ReportDropDownComponent,
    DateRowComponent,
    ProgramReportComponent,
    ProgramTableComponent,
    AdminDashboardComponent,
    FinancialsBoxComponent,
    CustomerTotalBoxComponent,
    CapacityBoxComponent,
    AlertsBoxComponent,
    ProgramDetailComponent,
    WaitListComponent,
    ComboChartComponent,
    ComboSeriesVerticalComponent,
    ViewFacilityComponent,
    DetailOutputExpectedComponent,
    FinancialReportComponent,
    FinancialReportTableComponent,
    UserAdminSettingComponent,
    FacilityAlertsComponent,
    FacilityAlertsReportComponent,
    FacilityAlertsReportTableComponent,
    EnrollmentAuditReportComponent,
    EnrollmentAuditReportTableComponent,
    EnrollmentAlertsReportComponent,
    EnrollmentAlertsReportTableComponent,
    CustomerAlertsReportComponent,
    CustomerAlertsReportTableComponent,
    AlertDialogComponent,
    AlertMainPageComponent,
    RSPControlsComponent,
    EnrollmentAlertsMainPageComponent,
    CustomerAlertsMainPageComponent,
    LoginTurnOffComponent,
    KwSliderComponent,
    FacilityAlertsMainPageComponent,
    EnrolledAllocationsReportComponent,
    EnrolledAllocationsReportTableComponent,
    TransferListComponent  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,
    MatCheckboxModule,
    MatTableModule,
    MatPaginatorModule,
    MatFormFieldModule,
    MatCardModule,
    MatDatepickerModule,
    MatSnackBarModule,
    MatNativeDateModule,
    MatRadioModule,
    MatSlideToggleModule,
    ReactiveFormsModule,
    DeLoaderModule,
    FormsModule,
    MatDialogModule,
    MatInputModule,
    DeFooterModule,
    DeHeaderModule,
    DeButtonModule,
    AppRoutingModule,
    MatExpansionModule,
    RouterModule,
    MatSelectModule,
    MatTooltipModule,
    DragDropModule,
    MatIconModule,
    TableVirtualScrollModule,
    NgxChartsModule,
    /* configure app with AmplifyUIAngularModule */
    AmplifyUIAngularModule,
    MsalModule,
    NgxTextDiffModule
  ],
  entryComponents: [
    IneligibilityReasonsDialogComponent,
    RSPEnrollmentDialogComponent,
    DetailsDialogComponent,
    EnrollmentFeeDialogComponent,
    ErrorDialogComponent,
    AlertDialogComponent
  ],
  providers: [
    ApiService,
    DataService,
    CustomerTableService,
    AllocationTableService,
    StyleHelperService,
    FacilityManagementService,
    SegmentService,

    {
      provide: HTTP_INTERCEPTORS,
      useClass: MsalInterceptor,
      multi: true
    },
    {
      provide: MSAL_INSTANCE,
      useFactory: MSALInstanceFactory
    },
    {
      provide: MSAL_GUARD_CONFIG,
      useFactory: MSALGuardConfigFactory
    },
    {
      provide: MSAL_INTERCEPTOR_CONFIG,
      useFactory: MSALInterceptorConfigFactory
    },
    MsalService,
    MsalGuard,
    MsalBroadcastService

  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  bootstrap: [AppComponent]
})
export class AppModule { }

// logging callback for MSAL system config
export function loggerCallback(logLevel, message, piiEnabled) {
  console.warn('From MSAL');
  console.log(message);
}

// const uri = 'https://iqlqamn90g.execute-api.us-east-1.amazonaws.com/dev/postgraphile'; // our GraphQL API